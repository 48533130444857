import * as React from "react";

import Page from "../components/Layout/Page";
import Container from "../components/Layout/Container";

const ResourcesPage = () => {
  return (
    <Page className="store">
      <section className="hero">
        <Container>
          <h1>
            <span>Interior Design</span>
            <strong>Hardware &amp; Accessories</strong>
          </h1>
        </Container>
      </section>
      <section className="main-content">
        <Container>
          <h3>
            <strong>Visit Our Showroom</strong>
          </h3>
          <div>
            <address>
              White Walls
              <br />
              Gr. Floor, Solomon's Cave,
              <br />
              Tuikual South,
              <br />
              Aizawl Mizoram
            </address>
            <div>
              (
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.co.in/maps/place/White+Walls/@23.727464,92.7157443,17z/data=!4m5!3m4!1s0x0:0x4dfdde92fef8209!8m2!3d23.727464!4d92.717933"
              >
                Get Directions
              </a>
              )
            </div>
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default ResourcesPage;
